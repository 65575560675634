.cards {
    padding: 3rem;
    padding-bottom: 0rem;
    background: #f4f7f9;
    min-height: calc(100vh - 230px);
  }

  .api-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 200px;
    box-sizing: border-box;
    justify-content: center;
}

.api-item > h1 {
    margin-top: 20px;
    margin-bottom: 12px;
    color: #0e3950;
    font-size: 2.5rem;
    font-weight: 700;
    font-family: 'Museo700-Regular';
  }

  .api-item > h2 {
    margin-top: 20px;
    margin-bottom: 12px;
    color: #0e3950;
    font-size: 1.8rem;
    font-weight: 700;
    font-family: 'Museo700-Regular';
  }

  .api-item > h3 {
    margin-top: 15px;
    margin-bottom: 5px;
    color: #0e3950;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Museo700-Regular';
  }
  .api-item > h4 {
    margin-top: 5px;
    margin-bottom: 20px;
    max-width: 1000px;
    text-align: center;
    color: #315467;
    font-size: 1.3rem;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
  }

  .api-item > h5 {
    margin-top: 5px;
    margin-bottom: 10px;
    max-width: 1000px;
    text-align: center;
    color: #315467;
    font-size: 1.3rem;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
  }

  .api-item > p {
    margin-top: 5px;
    margin-bottom: 20px;
    max-width: 1000px;
    text-align: center;
    color: #315467;
    font-size: 1rem;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
  }

  .api-item a:link{
    color: #0e3950;
  }
  
  .api-item a:visited{
    color: #0e3950;
  }
  
  .api-item a:hover{
    color: #fa9a39;
  }
  
  h1 {
    text-align: center;
    font-size: 40px;
  }

  .tr:nth-child(even) {
    background-color: #dddddd;
  }
  
  .cards__container {
    display: flex;
    padding-top: 0px;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .cards_holder{
    display: flex;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 3px;
    border-radius: 10px;
    min-width: 250px;
  }
  
  .cards__item__link {
    display: flex;
    color: #fff;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.03);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }

  .cards__item__link:hover > .cards__item__logo{
    color: #fa9a39;
  }

  .cards__item__link:hover > .cards__item__pic-wrap{
    background-color: #fa9a39;
  }

  .cards__item__link:hover > .cards__item__pic-wrap:after{
    background-color: #fa9a39;
  }
  
  .cards__item__pic-wrap {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    padding-top: 40px;
    overflow: hidden;
    background-color: #252e48;
  }

  .cards__item__logo {
    display: flex;
    justify-content: center;
    color: #252e48;
    padding-top: 15px;
    padding-bottom: 15px;
    background: #fff;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    background-color: #252e48;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    position: top;
    padding: 0px 30px 10px;
    background: #fff;
    justify-content: first baseline;
    height: 100%;
    padding-top: 10px;
  }

  .cards__item__header {
    color: #252e48;
    font-size: 25px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    text-align: center;
  }

  .cards__item__header_detail {
    color: #252e48;
    font-size: 10px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    text-align: center;
  }

  .cards__item__info > p {
    margin-bottom: 10px;
    color: #252e48;
  }

  .cards__item__text {
    color: #252e48;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }

  .tick{
    color:green;
  }

  .cross{
    color:red;
  }

  .api-item{
    color: #252e48;
    padding-bottom: 20px;
  }

  .api-item:hover{
    color: #fa9a39;
  }

  .code-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  }

  .code{
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }

  .code-wrapper{
    display: flex;
    padding-bottom: 20px;
  }

  .code-item {
    display: flex;
    padding-top: 10px;
    margin: 20px;
    background: #282c34;
    border-radius: 10px;
    flex-direction: column;
    box-sizing: border-box;
    width: 485px;
    align-items: center;

  }

  .code-item-header > h2{
    text-align: center;
    margin-top: 5px;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 700;
    font-family: 'Museo700-Regular';
  }

  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1025px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }

  @media screen and (max-width: 1024px) {
    .code-wrapper {
      flex-direction: column;
    }

    .code-item{
      width: 250px;
    }
  }