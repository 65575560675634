  .header-container {
    background: #010D1D;
    background: url('../images/img-home8-other.jpg') center center/cover no-repeat;
    height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .header-wrapper {
    margin-top: 50px;
  }

  .header-item-product-hunt {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 200px;
    box-sizing: border-box;
    justify-content: center;
  }
  
  .header-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 200px;
    box-sizing: border-box;
    justify-content: center;
  }

  .header-item > h1 {
    color: #fff;
    font-size: 60px;
    font-family: 'Museo700-Regular';
  }

  .header-item > h1:hover{
    color: #fa9a39;
  }
  
  .header-item > p {
    margin-top: 0px;
    color: #fff;
    font-size: 24px;
    font-family: 'Open Sans', sans-serif;
  }

  .header-form {
    overflow: hidden;
    margin-top: 30px;
  }

  input {
    width: 100%;
    height: 35px;
    float: left;
    padding: 15px 20px;
    font-size: 14px;
    outline: 0;
  }

  .header-form-url{
    border: 1px solid #fff;
    width: 75%;
    border-right-width: 0px;
    text-align: left;
    padding: 15px 10px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .header-form-submit{
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
    width: 25%;
    line-height: 0;
    text-align: center;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  input[type=submit]:hover {
    background-color: #fa9a39;
    color: #242424;
    border: 1px solid #fa9a39;
    transition: all 0.3s ease-out;
  }

  .button {
    width: 30px;
    height: 30px;
}

.result-wrapper {
  width: 100%;
}

.result-legit {
  background-color: rgba(30, 70, 32, 0.9);
  color: #fff;
  border-radius: 2px;
  width: 100%;
  padding-bottom: 10px;
  font-size: 13px;
}

.result-phish {
  background-color: rgba(97, 26, 21, 0.9);
  color: #fff;
  border-radius: 2px;
  width: 100%;
  padding-bottom: 10px;
  font-size: 13px;
}

.result-legit > h1 {
  margin-bottom: 5px;
  padding-top: 10px;
  color: #fff;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
}

.result-phish > h1 {
  margin-bottom: 5px;
  padding-top: 10px;
  color: #fff;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
}

.delete-icon {
  margin-top: 3px;
  margin-right: 3px;
  color: #ffffff80;
  float: right;
}

.delete-icon:hover {
  color: #fa9a39;
}

.terms-result-wrapper{
  margin-top: 10px;
}
.terms-link{
  font-size: 10px;
  
}

@media screen and (max-height: 375px) {
  .header-wrapper {
    margin-top: -100px;
  }
}
  
@media screen and (max-width: 768px) {

}

@media screen and (max-width: 425px) {
  form {
    margin: 0 auto;
    margin-top: 30px;
    width: 90%;
  }

  .result-legit {
    margin: 0 auto;
    width: 90%;
  }

  .result-phish {
    margin: 0 auto;
    width: 90%;
  }
}