.about-container{
    background: #f4f7f9;
    min-height: calc(100vh - 250px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
}

.about-wrapper{
    margin-top: 50px;
}

.contributor-wrapper{
  margin-top: 20px;
  margin-bottom: 40px;
}

.about-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 200px;
    box-sizing: border-box;
    justify-content: center;
}

.about-item > h1 {
    margin-top: 20px;
    margin-bottom: 12px;
    color: #0e3950;
    font-size: 2.5rem;
    font-weight: 700;
    font-family: 'Museo700-Regular';
  }

  .about-item > p {
    margin-top: 8px;
    margin-bottom: 40px;
    max-width: 1000px;
    text-align: center;
    color: #315467;
    font-size: 1.3rem;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
  }

  .about-item > ul {
    color: #315467;
    font-size: 1.3rem;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
  }

  .about-item strong {
    font-weight: 700;
}

.about-item  a:link{
  color: #0e3950;
}

.about-item  a:visited{
  color: #0e3950;
}

.about-item  a:hover{
  color: #fa9a39;
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin-top: 5px;
}

.logo-items > img {
  padding: 10px;
  opacity: 0.4;
}

.logo-items > img:hover {
  opacity: 1;
}