* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
  }

  @font-face {
    font-family: 'Museo700-Regular';
    src: url('Museo700-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
  
  .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {
    background-image: url('/src/images/img-2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .products {
    background-image: url('/src/images/img-1.jpg');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {
    background-image: url('/src/images/img-8.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .container-404{
    min-height: calc(100vh - 250px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-404 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: absolute;
    top: calc(50% - 80px);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .text-404{
    padding-top: 200px;
  }