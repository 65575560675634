.faq-container{
    background: #f4f7f9;
    min-height: calc(100vh - 250px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
}

.faq-wrapper{
    margin-top: 50px;
}

.faq-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 200px;
    box-sizing: border-box;
    justify-content: center;
}

.faq-item > h1 {
    margin-top: 20px;
    margin-bottom: 12px;
    color: #0e3950;
    font-size: 2.5rem;
    font-weight: 700;
    font-family: 'Museo700-Regular';
  }

  .faq-item > h2 {
    margin-top: 20px;
    margin-bottom: 12px;
    color: #315467;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Museo700-Regular';
  }

  .faq-item > p {
    margin-top: 8px;
    margin-bottom: 40px;
    max-width: 1000px;
    text-align: center;
    color: #315467;
    font-size: 1.3rem;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
  }

  .faq-item a:link{
    color: #0e3950;
  }
  
  .faq-item a:visited{
    color: #0e3950;
  }
  
  .faq-item a:hover{
    color: #fa9a39;
  }

  .faq-item strong {
    font-weight: 700;
}