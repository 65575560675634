.contact-container {
  background: #f4f7f9;
  min-height: calc(100vh - 230px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  color: #0e3950;
}
.contact-container a:link{
  color: #0e3950;
}

.contact-container a:visited{
  color: #0e3950;
}

.contact-container a:hover{
  color: #fa9a39;
}

.text-item-header{
  display: flex;
  margin-top: 50px;
}

.text-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 200px;
  box-sizing: border-box;
  justify-content: center;
}

.text-item-header > h1 {
  margin-top: 20px;
  margin-bottom: 12px;
  color: #0e3950;
  font-size: 2.5rem;
  font-weight: 700;
  font-family: 'Museo700-Regular';
}

.text-item > p {
  margin-top: 8px;
  margin-bottom: 30px;
  max-width: 1000px;
  text-align: center;
  color: #0e3950;
  font-size: 1.3rem;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  max-width: 90%;
}

.text-item-email > p {
  margin-top: 8px;
  margin-bottom: 30px;
  max-width: 1000px;
  text-align: center;
  color: #0e3950;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
}

.contact-wrapper {
  width: 450px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.03);
}

.form-item{
  padding: 25px;
}

.select-item{
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
}

.form-submit{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.contact-submit-button{
  background-color: transparent;
  color: #0e3950;
  border: 1px solid #0e3950;
  transition: all 0.3s ease-out;
  line-height: 0;
  text-align: center;
  border-radius: 2px;
  width: 100px;
}

.contact-submit-button-sent{
  background-color: green;
  color: #0e3950;
  border: 1px solid green;
  transition: all 0.3s ease-out;
  line-height: 0;
  text-align: center;
  border-radius: 2px;
  width: 100px;

}

.contact-submit-button:hover{
  background-color: #fa9a39;
  border: 1px solid #fa9a39;
  transition: all 0.3s ease-out;
  
}

.contact-inupt{
  border: 1px solid #0e3950;
  border-radius: 2px;
  width: 100%;
}

select{
  border: 1px solid #0e3950;
  border-radius: 2px;
  width: 100%;
}

label{
  font-size: 14px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
}

.contact-inupt:focus{
  border: 1px solid #fa9a39;
}

textarea{
  border: 1px solid #0e3950;
  border-radius: 2px;
  outline: none;
  height: 170px;
  width: 100%;
  padding-bottom: 5px;
}

textarea:focus{
  border: 1px solid #fa9a39;
}

@media screen and (max-width: 450px) {
  .contact-wrapper{
    width: 90%
  }
  .contact-submit-button{
    background-color: transparent;
    color: #0e3950;
    border: 1px solid #0e3950;
    transition: all 0.3s ease-out;
    width: 100px;
    line-height: 0;
    text-align: center;
    border-radius: 2px;
  }
}