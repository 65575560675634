.footer-container {
    background-color: #010D1D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 170px;
    position: static;
    bottom: 0;
    width: 100%
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px;
    text-align: center;
    width: 160px;
    box-sizing: border-box;
    justify-content: center;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
    margin-right: 5px;

  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin-top: 5px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin-bottom: 5px;
  }

  .footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    max-width: 2000px;
  }
  
  .social-logo {
    justify-self: start;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .social-title {
    color: #fff;
    justify-self: start;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    font-family: 'Museo700-Regular';
  }

  .social-title:hover {
    color: #fa9a39;
  }

  .website-rights {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin-bottom: 5px;
    color: #fff;
    
    text-align: center;
  }

  .website-rights:hover {
    color: #fa9a39;
  }

  .terms{
    text-align: center;
    margin-bottom: 10px;
  }

  .terms-link{
    color: #fff;
    padding-right: 5px;
  }
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 768px) {
  }