.home-content-container{
    background: #f4f7f9;
    min-height: calc(100vh - 760px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
}

.home-content-wrapper{
    margin-top: 50px;
    margin-bottom: 20px;
}

.home-content-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 200px;
    box-sizing: border-box;
    justify-content: center;
}

.home-content-item-legit {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 200px;
  box-sizing: border-box;
  justify-content: center;
  font-family: 'Museo700-Regular';
}

.home-content-item-legit h2 {
  font-family: 'Museo700-Regular';
}

.home-content-item-legit span {
  display: flex;
  background-color: rgba(119, 221, 119, 0.9);
  font-family: 'Museo700-Regular';
  font-size: 1.5rem;
}

.home-content-item-phish{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 200px;
  box-sizing: border-box;
  justify-content: center;
}

.home-content-item-phish h2 {
  font-family: 'Museo700-Regular';
}

.home-content-item-phish span {
  background-color: rgba(255, 105, 97, 0.9);
  font-family: 'Museo700-Regular';
  font-size: 1.5rem;
}

.home-content-item > h1 {
    margin-top: 20px;
    margin-bottom: 12px;
    color: #0e3950;
    font-size: 2.5rem;
    font-weight: 700;
    font-family: 'Museo700-Regular';
  }

  .home-content-item > p {
    margin-top: 8px;
    margin-bottom: 10px;
    max-width: 1000px;
    text-align: center;
    color: #315467;
    font-size: 1.3rem;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
  }

  .home-content-item strong {
    font-weight: 700;
}

.home-content-item a:link{
  color: #0e3950;
}

.home-content-item a:visited{
  color: #0e3950;
}

.home-content-item a:hover{
  color: #fa9a39;
}

.home-content-logo{
    color: #010D1D;
  }

.home-content-logo:hover{
    color: #fa9a39;
  }

@media screen and (max-width: 470px) {
  .home-content-item-phish span {
    font-size: 1.0rem;
  }
  .home-content-item-phish h2 {
    font-size: 1.0rem;
  }
  .home-content-item-legit span {
    font-size: 1.0rem;
  }
}