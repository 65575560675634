:root {
    --primary: #ffffff80;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: #252e48;
    color: #fff;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #ffffff80;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 1rem;
}

.btn--large {
    padding: 12px 26px;
    font-size: 1rem;
}

.btn--medium:hover, .btn--large:hover {
    background-color: #fa9a39;
    color: #242424;
    border: 1px solid #fa9a39;
    transition: all 0.3s ease-out;
}