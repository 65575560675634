.terms-container{
    background: #f4f7f9;
    min-height: calc(100vh - 230px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
}

.terms-wrapper{
    margin-top: 50px;
}

.terms-item{
    display: flex;
    flex-direction: column;
    align-items: left;
    min-width: 200px;
    box-sizing: border-box;
    justify-content: center;
    margin-bottom: 30px;
}

.terms-item-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
    box-sizing: border-box;
    justify-content: center;
    margin-bottom: 20px;
}

.terms-item-header > h1 {
    margin-top: 20px;
    margin-bottom: 12px;
    color: #0e3950;
    font-size: 2.5rem;
    font-weight: 700;
    font-family: 'Museo700-Regular';
  }

  .terms-item-description > p {
    margin-top: 15px;
    padding: 20px;
    max-width: 1000px;
    text-align: left;
    color: #315467;
    font-size: 1.3rem;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
  }

  .terms-item-description strong{
    font-weight: 700;
  }

.terms-item > h2 {
    margin-top: 40px;
    margin-bottom: 12px;
    padding: 20px;
    color: #0e3950;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    }

  .terms-item > p {
    margin-top: 15px;
    padding: 20px;
    max-width: 1000px;
    text-align: left;
    color: #315467;
    font-size: 1.3rem;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
  }