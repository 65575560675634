.stats-container {
  background-color: #010D1D;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
}

.stats {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.stats-wrapper {
  display: flex;
}

.stats-items {
  color: #ffffff80;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
  text-align: center;
  max-width: 100px;
  box-sizing: border-box;
}

.stats-items:hover {
  color: #fa9a39;
}

.stats-items h2 {
  margin-bottom: 5px;
}

@media screen and (max-width: 820px) {
  .stats-wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}